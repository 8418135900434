.actor-photo{
    height: 200px;
    width: 200px;
}
.actor-photo-container{
    height: 200px;
    width: 200px;
    background-color: #C6C6C6;
    display: flex;
    align-items: center;
    justify-content: center;
}
