
.cast-photo-container{
    /* height: 100%; */
    position: relative;
    background-color: #C6C6C6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.square-crop{
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    width: 100%;
    font-size: 25%;
    object-position: 0px -20px;
}

.overlay {
    position: absolute;
    color: grey;
    top: 0;
    right: 0;
    height: 20%;
    width: 20%;
}

.icon-username{
    width: 50%;
    height: 50%;
}